import React, { createContext, useState, useEffect } from 'react';
import { GetStartPoint } from '../Api.js';
import L, { map } from 'leaflet';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
    /**
     * StartPoint is the place to start the map
     */
    const [startPoint, setStartPoint] = useState(undefined);

    /**
     * Fetch the startpoint Once at startup
     */
    useEffect(() => {
        const fetchData = async () => {
            try {

                const params = new URLSearchParams(window.location.search)
                let search = params.get('s')

                if (search != "" && search !== null) {
                    const geocoder = L.Control.Geocoder.nominatim();

                    geocoder.geocode(search, (results) => {
                        if (results.length > 0) {
                            const result = results[0]; // Take the first result
                            const { lat, lng } = result.center;

                            setStartPoint({
                                lat: lat,
                                lng: lng,
                                center: result.center,
                            });
                        } else {
                            console.error('No results found');
                        }
                    });
                } else {
                    const startPointData = await GetStartPoint();
                    if (startPointData !== undefined) {
                        setStartPoint({
                            lat: startPointData.startLat,
                            lng: startPointData.startLng,
                        });
                    }
                }

            } catch (error) {
                console.error('Error fetching start point:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <ClientContext.Provider value={{ startPoint }}>
            {children}
        </ClientContext.Provider>
    );
};