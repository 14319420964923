const API_URL = process.env.REACT_APP_API_URL;


/**
 * GetAnalysis is used to fetch an analysis package from the backend
 * @param {*} lat 
 * @param {*} lng 
 * @param {*} radius 
 * @param {*} orign - extra check in frontend, double check is done in backend
 */
export const GetAnalysis = (lat, lng, radius, origin) => {
  return new Promise((resolve, reject) => {
    const bodyData = { lat: lat, lng: lng, radius: radius, origin: origin };
    fetch(API_URL + "/analyze", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyData)
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            const errorMessage = `HTTP error! Status: ${response.status}, Message: ${errorData.message}`;
            throw new Error(errorMessage);
          });
        }
        return response.json();
      })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        reject(error);
      });
  });
};

/**
 * GetStartPoint will fetch the starting point of the application
 * @returns 
 */
export const GetStartPoint = async () => {
  try {
    const query = `${API_URL}/getStartPoint`;
    const response = await fetch(query);

    if (!response.ok) {
      throw new Error(`Error fetching start point. Status: ${response.status}`);
    }

    const data = await response.json();
    const startPoint = data.startPoint;
    return startPoint;
  } catch (error) {
    console.error('Error in GetStartPoint:', error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};