import React from 'react';
import './AnalyzePackageSuggester.css';

const AnalyzePackage = ({ analysisResult }) => {
  if (!analysisResult || analysisResult.length === 0) {
    return <div>No analysis available</div>;
  }

  return (
    <div className="analysis-container">
      <div className="analysis-title">Områdestyp</div>
      <div className="analysis-title">Inom radie</div>
      <div className="analysis-title">Analyspaket</div>
      <div className="analysis-title">Pris</div>

      {analysisResult.map((entity, index) => (
        <React.Fragment key={index}>
          <div>{entity.description}</div>
          <div>{entity.distance} m</div>
          <div>{entity.analysisPackage}</div>
          <div>{entity.price} kr</div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default AnalyzePackage;