import proj4 from 'proj4';

export function convertCoordinates(sourceEPSG, destEPSG, coordinates){
    if(sourceEPSG === 3857 && destEPSG === 4326) {
        return convertCoords(coordinates[0], coordinates[1])
    }
}


const convertCoords = (E, N) => {
    const epsg3857 = "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +no_defs"; // Proj4 string for EPSG:3857 (Web Mercator)
    const epsg4326 = "EPSG:4326"; // WGS 84

    return proj4(epsg3857, epsg4326, [E, N]);
};